.page {
  position: relative;
  width: 100%;
}

.header {
  background: #3276f6;
  width: 100%;
  height: 180px;
  padding: 9px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;

  .headerTitle {
    color: #fafbfe;
    font-size: 18px;
    padding-left: 11px;
    margin-bottom: 11px;
    font-weight: 500;
    text-align: left;
    //font-style: italic;
    vertical-align: middle;
  }

  .tagContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 9px;

    a {
      margin-left: 9px;
      margin-bottom: 9px;
      width: 60px;
      font-size: 15px;
      height: 32px;
      line-height: 32px;
      text-decoration: none;
    }
  }

  .dateContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 9px;


    a {
      margin-left: 9px;
      margin-bottom: 9px;
      width: 30px;
      font-size: 15px;
      height: 30px;
      line-height: 30px;
      text-decoration: none;
    }

    .calendar {
      font-size: 15px;
      height: 30px;
      line-height: 30px;
      position: absolute;
      right: 9px;
      color: #fafbfe;
      background: #4587f7;

      .left {
        margin-right: 9px;
        display: inline-block;
        margin-left: 9px;

        span {
          margin-right: 5px;
        }
      }

      .right {
        display: inline-block;
        padding-right: 5px;
      }
    }
  }
}

.contentCard {
  background: #fff;
  width: 95%;
  height: 125px;
  padding: 15px 30px;
  border-radius: 15px;
  position: absolute !important;
  top: 140px;
  z-index: 100;
  margin-left: 2.5%;
  margin-right: 2.5%;
  font-size: 18px;
  text-align: center;


  .headerLine {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px
  }

  .tag {
    background-color: #fff;
    color: #888;
    font-size: 15px;
    line-height: 26px;
  }

  .cardTitle {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .cardAmount {
    font-size: 18px;
    font-weight: 500;
    color: #d37142;
    margin-bottom: 10px;
    text-align: center
  }

}

.placeholder {
  height: 100px
}

.amount {
  font-weight: 500;
  color: #d37142;
  margin-bottom: 10px;
  text-align: center
}

.dateTag {
  background-color: #fff;
  color: #888;
  font-size: 15px;
  line-height: 26px;
}